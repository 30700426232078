<template>
  <div class="join done center">
    <div class="baseBox">
      <div class="joinBox">
        <h2>설문에 참여해주셔서 감사합니다.</h2>
        <h3>설문마감 이후 당첨여부 확인이 가능합니다.</h3>
        <div class="buttonWrap">
          <router-link to="/">
            <button class="large point">확인</button>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  methods: {
    moveBack() {
      this.$router.go(-1);
    },
  },
};
</script>
